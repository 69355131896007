import PauAssignment from "./PauAssignment";
import { getUrlHost } from "../../constants/getUrlHost";

export const logicSelectElement = ( projectId, hotspotId ) => {

  'use strict';

  let $pauSelectorView = document.querySelector( "#pau-selector-view h4#pauRute" ),
    $pauSaveAssig    = document.querySelector( "#pauSaveAssig" ),
    $pauDataPath     = document.querySelector( "#pauDataPath" );

  let xPath      = "",
    xPathFinal = "";

  let url = `admin/projects/${projectId}/?hotspot_id=${hotspotId}&element=`,
    $selectorAssig;
  url = process.env.NODE_ENV !== 'production'
    ? `http://192.168.101.12:8000/${url}`
    : `https://my-pau.com/${url}`;

  let pauAssig = new PauAssignment( $pauSaveAssig );

  document.body.not( ".pauNoEventAssig, .pauNoEventAssig *" )
    .on( "mouseover", function ( e ) {

      if ( e.target.id.indexOf( 'selector' ) !== -1 || e.target.tagName === 'BODY' || e.target.tagName === 'HTML' || e.shiftKey ) return;

      let $target      = e.target,
        targetOffset = $target.getBoundingClientRect();

      $target.not( ".pauNoEventAssig, .pauNoEventAssig *" ).addClass( 'pau-onlive' );

    } ).on( "mouseout", function ( e ) {

    if ( e.target.id.indexOf( 'selector' ) !== -1 || e.target.tagName === 'BODY' || e.target.tagName === 'HTML' ) return;

    let $target = e.target;

    $target.not( ".pauNoEventAssig, .pauNoEventAssig *" ).removeClass( 'pau-onlive' );

  } ).on( "click", function ( e ) {

    if ( e.target.id.indexOf( 'selector' ) !== -1 || e.target.tagName === 'BODY' || e.target.tagName === 'HTML' ) return;
    if ( e.target.id === 'draggable' ) return;


    let $target = e.target,
      id      = $target.getAttribute( "id" );

    if( e.shiftKey ) {

      e.preventDefault();

      const tagA = $target.closest( "a" );

      if ( $target.tagName === 'A' || tagA ) {

        $target = tagA || $target;
        let href = $target.getAttribute( "href" );

        if ( href ) {

          let urlHost = getUrlHost();
          let firstLetter = href.charAt( 0 );

          if ( firstLetter === "/" ) {
            href = href.substring( 1 );
          }

          if ( href.includes( urlHost ) ) {
            href = href.replace( urlHost, "" );
          }

          let urlGo;
          if ( href.includes( '#' ) ) {
            urlGo = urlHost + location.search + href;
          } else {
            href += href.includes( '?' ) ? '&' : '?';
            urlGo = urlHost + href + location.search.substring( 1 );
          }

          location.href = urlGo;

        }

      }

      return;

    }

    $selectorAssig = $target;

    if ( $target.matches( '.pauNoEventAssig, .pauNoEventAssig *' ) ) return;

    e.preventDefault();

    xPath = pauAssig.getXPath( $target ) || '';
    xPath = xPath.length > 0 ? xPath.trim() : xPath;

    let referencia = document.querySelectorAll( xPath );
    if( referencia.length > 1 ) {
      let pos  = Array.from( referencia ).indexOf( $target );
      if( pos  !== -1 ) xPath = xPath+`;${pos}`;

    }

    $pauSelectorView.innerHTML = xPath;
    $pauDataPath.value = xPath;


  } );

  $pauSaveAssig.on( "click", () => {
    location.href = url + xPath.replace( "#", "PAUNUM" );
  } );

};
