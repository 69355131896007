import React, { useEffect } from 'react';
import './index.css';
import App from './App';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from "react-dom/client";
import { log } from "./constants/log";
import './includes/HelpersDOM';
import fetchX from "./includes/fetchSetup";
import { getUrlHost } from "./constants/getUrlHost";
import {isMobile} from "react-device-detect";
import Assignment from "./includes/Assignment/Assignment";
import { logicSelectElement } from "./includes/Assignment/logicSelectElement";

global.pauActiveElement = false;

document.addEventListener( "DOMContentLoaded", () => {

  let interactiveTags = `a,select,button,input,textarea`;
  
  /**
   * Agrega el atributo tabindex = 1 a las etiquetas interactivas declaradas anteriormente
   * con el fin de que el panel de Pau sea el último en enfocarse cuando se utiliza la navegación por tab
   */
  document.querySelectorAll( interactiveTags ).forEach( element => {
    if( !element.matches(  'itemPau' ) ) {

      if( !element.hasAttribute( 'tabindex' ) ) {
        element.tabIndex = 2;
      }
    }
  } );

  /**
   * Desactiva el menu contextual en dispositivios mobiles para asegurar
   * el correcto funcionamiento de los hotspots
   */
  if( isMobile ) {
    document.addEventListener( 'contextmenu', ( e)=> {
      e.preventDefault();
    })
  }

  let closePanel = function() {

    let $pauMainAccess = document.querySelector( "#pauMainAccess" ),
        $pauContMain   = document.querySelector( '#pauContMain' ),
        $iconPauAccess = document.querySelector( '.iconPauAccess' );

    $iconPauAccess.removeClass( 'pauPanelDesplegado' );

    $pauContMain.setAttribute( "data-value", '1' );

    $pauMainAccess
        .css( 'height', '80px' )
        .anime( {
          right: '-450px'
        } );

    $iconPauAccess.css( {
      "width"     : "80px",
      "height"    : "80px",
      "padding"   : "0",
      "background": "transparent"
    } );

    $iconPauAccess.innerHTML = global.pauIconsMain.ico_principal_pau;
    document.activeElement.blur();
    global.pauActiveElement = false;

  }

  // función que se encarga de cerrar el panel cuando se presiona la tecla escpae
  document.addEventListener( 'keyup', ( e ) => {

    if ( e.key === 'Escape' && global.pauActiveElement ) {
      closePanel();
    }

  });

  /*
  * Función que se encarga de cerrar el panel cuando se hace un click
  * en cualquier parte fuera de él
  */

  let closePanelEvent = isMobile ? 'touchstart' : 'click';

  document.addEventListener( closePanelEvent, ( e ) => {
    if( pauActiveElement && !pauMainAccess.contains( e.target ) ) {
      closePanel();
    }
  })

  let urlParams = new URLSearchParams( location.search ),
      paumode   = urlParams.get( 'paumode' );
  if ( paumode === 'assignment' ) {
    let pauAssignment = document.createElement( 'div' ),
        projectId     = urlParams.get( 'project_id' ),
        hotspotId     = urlParams.get( 'hotspot_id' );
    pauAssignment.setAttribute( 'id', 'pauAssignment' );
    document.body.insertAdjacentElement( 'beforeend', pauAssignment );
    function AppWithCallbackAfterRender() {
      useEffect(() => {
        logicSelectElement( projectId, hotspotId );
      });
      return <Assignment projectId={projectId} hotspotId={hotspotId}/>;
    }
    ReactDOM.createRoot( pauAssignment ).render(
      <AppWithCallbackAfterRender />
    );
  }

  let pauMainAccess = document.createElement( 'div' );
  pauMainAccess.setAttribute( 'id', 'pauMainAccess' );
  document.body.append( pauMainAccess );

  global.urlApiBase = process.env.NODE_ENV !== 'production'
                        ? 'http://192.168.101.12:8000/api/v2/'
                        : 'https://my-pau.com/api/v2/';

  if ( global.accessToken === undefined ) {
    log( 'Se necesita establecer el accessToken para la conexión con Cloud PAU' );
  } else if ( global.license === undefined ) {
    log( 'Se necesita establecer la licencia para la conexión con Cloud PAU' );
  } else {

    global.urlBase = getUrlHost();

    global.headers = {
      'Authorization': `Bearer ${global.accessToken}`,
      'token'        : global.license,
    };

    let urlPauActive       = `${urlApiBase}pauActive`,
        pauActive          = false,
        promisePauActivate = fetchX( urlPauActive, ( data ) => {
         pauActive = data.pauActive;
        } );

    promisePauActivate.then( _ => {

      if ( pauActive && !global.pauActiveElement ) {
        global.pauActiveElement = true;

        ReactDOM.createRoot( pauMainAccess ).render(
          <CookiesProvider>
            <App/>
          </CookiesProvider>
        );

      }

    } );

  }

} );
