import tippy from "tippy.js/headless";
import { extendsObj } from "../constants/extendsObj";
import {isEmptyNull} from "../constants/isEmpty";
export const createTippy = ( $elem , options, corrections, type = 'none' ) => {

  let slice = 0,
    distance = 0,
    [ x , y ] = options.offset;

  switch ( options.placement ) {
    case 'top' :
      slice = x;
      y  *= -1
      distance += y;
      break;
    case 'bottom':
      distance += y;
      slice = x;
      break;
    case 'right' :
      distance += x;
      slice += y;
      break;
    case 'left' :
      x *= -1;
      distance += x;
      slice = y;
      break;
  }

  if( corrections && type === 'menu'  ) {

    corrections.width *= 16;
    corrections.height *= 16;

    if( options.placement === 'top' || options.placement === 'bottom' ) {
      distance -= corrections.height;

    }else{
      distance -= corrections.width;
    }

   let optsMenu = {
     trigger: 'manual',
     hideOnClick: false,
     showOnCreate: true,
     popperOptions : {
       modifiers: [
         {
           name: 'flip',
           options: {
             fallbackPlacements: [ options.placement ],
           } } ]
     },
   }
    options = {
      ...options,
      ...optsMenu
    }
  }

  options.offset = [ slice, distance ];

  const defaultOptions = {
    content: $elem.getAttribute("title"),
    allowHTML : true,
    interactive: true,
    interactiveBorder: 10,
    zIndex : 9999999999,
    appendTo: () => document.body,
    render( instance ) {
      // The recommended structure is to use the popper as an outer wrapper
      // element, with an inner `box` element
      const popper = document.createElement('div');
      const box = document.createElement('div');

      popper.appendChild( box );
      box.innerHTML = instance.props.content;



      function onUpdate(prevProps, nextProps) {
        // DOM diffing
        if (prevProps.content !== nextProps.content) {
          box.textContent = nextProps.content;
        }
      }
      // Return an object with two properties:
      // - `popper` (the root popper element)
      // - `onUpdate` callback whenever .setProps() or .setContent() is called
      return {
        popper,
      };
    }
  }

  const opts = extendsObj( {}, defaultOptions , options );

  return tippy( $elem, opts );

}



