let {
  protocol,
  host,
  pathname
} = location;
/* actual : devuelve la ruta en la que se encuentra el usuario actualmente
   home : devuelve la ruta de inicio
   isHome : retorna true si el usuario se encuentra en la página de inicio
* */
export const personalLocation = () => {
   return {
     actual: protocol+'//'+host+pathname,
     home: protocol+'//'+host+'/',
     isHome: pathname === '/'
   }

}
