import Cookies from 'js-cookie';
import { isMobile } from 'react-device-detect';
import { isEmptyNull } from "../constants/isEmpty";
import { isNumeric } from "../constants/isNumeric";
import { paulog } from "../constants/paulog";
import { personalLocation } from "../constants/personalLocation";
import { inherited } from "./inheritedHotspot";
import { createTippy } from "../includes/createTippy";



class ManagerHotspot {

  constructor() {
    this.tagsWithOutClosing = [ 'img', 'svg', 'input' ];
    this.hotspots = null;
    // this.licenseState = licenseState;
    this.pauReactPageVideo = document.querySelector( "#pauReactPageVideo" );
    this.pauReactPageAudio = document.querySelector( "#pauReactPageAudio" );
    this.pauReactLocucionLSE = document.querySelector( "#pauReactLocucionLSE" );
    this.videoRender = this.pauReactPageVideo;
    this.audioRender = this.pauReactPageAudio;
    this.isMsgInitLSE = true;

  }

  addQAuto( url ,isImage = false) {

    if( isEmptyNull( url) || !isEmptyNull( url.match(/q_auto/) ) ) return url;

    let firstPartURL, secondPartURL;

    try {

      if( !isImage ) {
        firstPartURL = url.match(/(http[s]?:\/\/)?(w{3}\.)?res\.cloudinary.com\/[a-zA-Z0-9\-]+\/(video\/)?(upload\/)?/)[0];
      }else {
        firstPartURL = url.match(/(http[s]?:\/\/)?(w{3}\.)?res\.cloudinary.com\/[a-zA-Z0-9\-]+\/(image\/)?(upload\/)?/)[0];
      }

    }catch ( e ) {
      paulog( `NO fue posible agregarle q_auto a la url: ${ url }`);
      return url;
    }

    secondPartURL = url.replace( firstPartURL, '' );
    return `${ firstPartURL }q_auto/${ secondPartURL }`
  }

  getStyleValue( elem , property ) {
    let styleElement = getComputedStyle( elem );
    return parseInt( styleElement.getPropertyValue( property ).replace( 'px','' ) );
  }

  addPadding( elem, width, height , placement ) {
    width  *= 16;
    height *= 16;
    let addedPadding;

    switch ( placement ) {

      case 'top':

        let elementPaddingTop = this.getStyleValue( elem , 'padding-top' );

        addedPadding = height - elementPaddingTop;

        if( addedPadding > 0 ) {
          elementPaddingTop += addedPadding;
          elem.style.paddingTop = `${ elementPaddingTop}px`;
        }

        if( elem.offsetWidth < width ) {
          let differenceWidth     = width - elem.offsetWidth;
          elem.style.paddingRight = `${ this.getStyleValue( elem, 'padding-right' ) + differenceWidth/2 }px`
          elem.style.paddingLeft  = `${ this.getStyleValue( elem, 'padding-left' ) + differenceWidth/2 }px`
        }
        break;

      case 'right' :

        let elementPaddingRight = this.getStyleValue( elem, 'padding-right' );

        addedPadding = width - elementPaddingRight;

        if( addedPadding > 0 ) {
          elementPaddingRight     += addedPadding;
          elem.style.paddingRight = `${ elementPaddingRight }px`;
        }

        if( elem.offsetHeight < height ) {
          let differenceHeight = height - elem.offsetHeight;
          elem.style.paddingTop = `${ this.getStyleValue( elem, 'padding-top' ) + differenceHeight }px`
        }

        break;

      case 'left':

        let elementPaddingLeft = this.getStyleValue( elem, 'padding-left' );

        addedPadding = width - elementPaddingLeft;

        if( addedPadding > 0 ) {
          elementPaddingLeft     += addedPadding;
          elem.style.paddingLeft = `${ elementPaddingLeft }px`;
        }

        if( elem.offsetHeight < height ) {
          let differenceHeight = height - elem.offsetHeight;
          elem.style.paddingTop = `${ this.getStyleValue( elem, 'padding-top' ) + differenceHeight }px`
        }

        break;

      case 'bottom':

        let elementPaddingBottom = this.getStyleValue( elem , 'padding-bottom' );

        addedPadding = height - elementPaddingBottom;

        if( addedPadding > 0 ) {
          elementPaddingBottom     += addedPadding;
          elem.style.paddingBottom = `${ elementPaddingBottom }px`;
        }

        if( elem.offsetWidth < width ) {
          let differenceWidth = width - elem.offsetWidth;
          elem.style.paddingRight = `${ this.getStyleValue( elem , 'padding-right' ) + differenceWidth/2 }px`
          elem.style.paddingLeft  = `${ this.getStyleValue( elem , 'padding-left' ) + differenceWidth/2 }px`
        }
        break;
    }

  }

  ucFirst( str ) {
    return str.charAt( 0 ).toUpperCase() + str.slice( 1 ).toLowerCase();
  }
  // verifica que un elemento del corresponda a una etiqueta sin cierre (input,img,svg)
  isTagWithOutClosing( $elem ){
    return this.tagsWithOutClosing.includes( $elem.tagName.toLowerCase() );
  }

  //aplica una correciòn a la posiciòn del especificado
  applyPositionCorrection( position_correction , $elem ) {
    if( position_correction !== 'none' ) {

      $elem?.css( {
        display        : 'flex',
        justifyContent : position_correction,
      } );

    }
  }

  getVideoSources( video ) {

    let videoMp4  = video.mp4  ? `<source type="video/mp4"  src="${ this.addQAuto( video.mp4 ) }">`  : '',
        videoOgg  = video.ogv  ? `<source type="video/ogv"  src="${ this.addQAuto( video.ogv ) }">`  : '',
        videoWebm = video.webm ? `<source type="video/webm" src="${ this.addQAuto( video.webm ) }">` : '';

    return videoMp4 + videoOgg + videoWebm;

  }

  getAudioSources( audio ) {

    let audioMp3 = audio.mp3 ? `<source type="audio/mpeg" src="${ this.addQAuto( audio.mp3 ) }">` : '',
        audioOgg = audio.ogg ? `<source type="audio/ogg"  src="${ this.addQAuto( audio.ogg ) }">` : '';

    return audioMp3 + audioOgg;

  }

  pictogramEmergent( pictogram_url, video ) {

    let erPicto   = /(http[s]?:\/\/)?(w{3}\.)?res\.cloudinary.com\/[a-zA-Z0-9\-]+\/(image\/)?(upload\/)?(v[0-9\-]+\/)?/,
        erVideo   = /(http[s]?:\/\/)?(w{3}\.)?res\.cloudinary.com\/[a-zA-Z0-9\-]+\/(video\/)?(upload\/)?(v[0-9\-]+\/)?/,
        base      = video.mp4.match( erVideo )[ 0 ],
        pictoBase = pictogram_url.replace( erPicto, '' ).replaceAll( '/', ':' ),
        videoBase = video.mp4.replace( erVideo, '' ).replace( /\.mp4/, '' ),
        urlFinal  = `${base}w_200,h_200/w_200,h_200,l_${pictoBase},fl_splice,du_1/so_0,fl_layer_apply,b_rgb:ffffff,co_rgb:ffffff/${videoBase}`;

    return {
      mp4 : `${ urlFinal }.mp4`,
      ogv : `${ urlFinal }.ogv`,
      webm: `${ urlFinal }.webm`
    }

  }
  
  typeEmergentTooltip( $elem, contentTippy, placement, x, y, handleEventInside, handleEventOut ) {

    let tooltip = createTippy( $elem, {
      content  : contentTippy,
      touch    : 'hold',
      placement: placement === 'none' ? 'top' : placement,
      offset   : [ x, y ],
      onMount( instance ) {
        handleEventInside( instance );
      },
      onHide( instance ) {
        handleEventOut();
      }
    } );

    $elem.addEventListener( 'touchend', () => {
      tooltip.hide();
    } );

  }

  typeEmbeddedTooltip( $elem, corrections, placement, contentTippy, x, y, eventInside, handleEventInside, eventOut, handleEventOut ) {

    this.addPadding( $elem, corrections.width, corrections.height, placement );

    createTippy( $elem, {
      content  : contentTippy,
      appendTo : () => $elem,
      placement: placement === 'none' ? 'top' : placement,
      offset   : [ x, y ],
      onMount( instance ) {
        $elem.on( eventInside, handleEventInside );
        $elem.on( eventOut, handleEventOut );
        instance.popper.css( {
          'pointer-events': 'none'
        } )
      }
    }, corrections, 'menu');
  }

  typeEmbeddedAbsolute( elem, corrections, containerhotspot, id , placement, x = 0, y = 0, eventInside, handleEventInside, eventOut, handleEventOut ) {

    let wrapper;

    if( !this.isTagWithOutClosing( elem ) ) {

      elem.insertAdjacentHTML( 'afterbegin', containerhotspot );
      wrapper = elem.firstElementChild
      elem.css( {
        position: 'relative'
      } );
      this.addPadding( elem, corrections.width, corrections.height, placement  );

    } else {
      elem.insertAdjacentHTML( "beforebegin", containerhotspot );
      wrapper = elem.previousElementSibling;
      elem.parentElement.css( {
        position : 'relative'
      } )
      this.addPadding( elem.parentElement, corrections.width, corrections.height, placement  );
    }

      wrapper.css( {
        position: 'absolute',
        'pointer-events' : 'none'
      } )
    
      switch ( placement ) {
        case 'top':
          wrapper.css( {
            top       : `${ y }px`,
            left      : `calc( 50% + ${ x }px )`,
            transform : 'translateX( -50% )'
          } )

          break;

        case 'right' :

          wrapper.css( {
            right     : `${ x }px`,
            top       : `calc(50% + ${ y }px`,
            transform : `translateY(-50%)`

          } )

          break;

        case 'left':

          wrapper.css( {
            left      : `${ x }px`,
            top       : `calc(50% + ${ y }px`,
            transform : `translateY(-50%)`
          } )
          break;

        case 'bottom':

          wrapper.css( {
            bottom    : `${ y }px`,
            left      : `calc( 50% + ${ x }px )`,
            transform : 'translateX( -50% )'
          } )

          break;
      }
    elem.on( eventInside, handleEventInside );
    elem.on( eventOut, handleEventOut );
  }

  init( hotspots ) {

    this.hotspots = hotspots;

    let $pauMainAccess = document.querySelector( "#pauMainAccess" );
    // isAdministrator = PauHelpers.attrToBoolean( $pauMainAccess.getAttribute( "pau-is-administrator" ) );
    for ( let hotspot of this.hotspots ) {
      /*
        si el hotspot tiene propiedades moviles heredadas o nulas, estas toman el valor
        de las que no son moviles
      * */
      hotspot = inherited( hotspot );

      let {
            id, data_media,
            page, lang_code, unique_name,
            element_path, element_path_text, type,
            state,
            onlyHomePage,
            pictogram_name,
            image_url,
            pictogram_emergent,
            pictogram_url,
            pictogram_url_hover,
            pictogram_onclick_url,
            pictogram_onclick_state,
            container_width,
            container_width_mobile,
            container_height,
            container_height_mobile,
            x_axis_position,
            x_axis_position_mobile,
            y_axis_position,
            y_axis_position_mobile,
            position,
            position_mobile,
            position_correction,
            position_correction_mobile,
            emergent_container_width,
            emergent_container_width_mobile,
            emergent_container_height,
            emergent_container_height_mobile,
            audio_dynamic,
            video_dynamic,
            url_audio_dynamic,
            url_video_dynamic,
            custom_class,
            custom_class_mobile
          } = hotspot;

      let posQuery = '';

      if( !isEmptyNull( element_path ) ) {
        let indexPosQuery = element_path.indexOf( ';' );

        if( indexPosQuery !== -1 ) {
          posQuery     = element_path.substring( indexPosQuery ).replace( ';','' );
          element_path = element_path.replace( ';' + posQuery , '' );
          posQuery     = parseInt( posQuery );
        }

      }

     if( type === 'emergent' ) pictogram_emergent = 'active';

      global.hotspot = hotspot;

      if ( !isEmptyNull( page ) && location.pathname !== page ) {
        continue;
      }

      /*si el hotspot tiene la opción de aparecer solo en la página de inicio
        y no estamos en dicha página, se descarta
       */
      if( onlyHomePage === 'on' && !personalLocation().isHome ) {
        continue;
      }

      if ( data_media ) {
        var { video, audio } = JSON.parse( data_media.replace( /\\/g, "" ) );
      } else {
        var video = {},
            audio = {};
      }

      if ( !isEmptyNull( audio_dynamic ) ) {
        audio[ 'mp3' ] = `${ url_audio_dynamic }.mp3`;
        audio[ 'ogg' ] = `${ url_audio_dynamic }.ogg`;
      }

      if ( !isEmptyNull( video_dynamic ) ) {
        video[ 'mp4' ]  = `${ url_video_dynamic }.mp4`;
        video[ 'ogv' ]  = `${ url_video_dynamic }.ogv`;
        video[ 'webm' ] = `${ url_video_dynamic }.webm`;
      }

      let corrections = !isMobile ? {
        width           : parseFloat( container_width ) ,
        height          : parseFloat( container_height ),
        x               : parseFloat( x_axis_position ) * 16,
        y               : parseFloat( y_axis_position ) * 16,
        width_emergent  : parseFloat( emergent_container_width ),
        height_emergent : parseFloat( emergent_container_height )
      } : {
        width           : parseFloat( container_width_mobile ),
        height          : parseFloat( container_height_mobile ),
        x               : parseFloat( x_axis_position_mobile ) * 16,
        y               : parseFloat( y_axis_position_mobile ) * 16,
        width_emergent  : parseFloat( emergent_container_width_mobile ),
        height_emergent : parseFloat( emergent_container_height_mobile )
      };

      if( isMobile ){
        position            = position_mobile;
        position_correction = position_correction_mobile;
        custom_class        = custom_class_mobile;
      }


      if (
        !isEmptyNull( element_path ) ||
        !isEmptyNull( unique_name ) ||
        type === 'pageContent'
      ) {

        if ( state === "active" ) {

          // Verificando Pictograma Emergente

          if ( pictogram_emergent === 'active' && !isEmptyNull( video_dynamic ) ) {

            if( !isEmptyNull( pictogram_url ) ) {
              video = this.pictogramEmergent( pictogram_url, video );
            }

            if ( !isEmptyNull( image_url ) ) {
              pictogram_url = image_url;
            } else {
              pictogram_url = '';
            }

          }

          let $elem = null;

          if ( type !== 'pageContent' ) {

            let selectors = `${ unique_name } ${ element_path }`.replace( /(null|false)/, '' );

            try {
              $elem = document.querySelectorAll( selectors );

              if ( $elem.length > 1 ) {
                  if( !isEmptyNull( posQuery ) ) {
                    $elem = $elem[ posQuery ];
                  }
              } else {
                  $elem = $elem [ 0 ];
               }

              if( $elem ) {
                $elem.css( {'user-select': 'none' } );
                $elem.setAttribute('data-hotspot-id', id );
              }

            } catch ( e ) {
              paulog( `La ruta ${selectors} del elemento con ID ${ id } no es válida, por favor verifica que esté escrita correctamente. Si necesitas ayuda ponte en contacto con un desarrollador.` );
              continue;
            }

            if ( !$elem ) {
              paulog( `No se encontró el elemento: "${ selectors }" con el ID: ${ id }. Por favor verifica que la ruta del elemento sea correcta y que esté presente en esta página.` );
              continue;
            }

            global.hotspot.$elem = $elem;
            if( !this.isTagWithOutClosing( $elem ) && type !== 'emergent' ) {
              this.applyPositionCorrection( position_correction , $elem );
            }
          }

          switch ( type ) {

            case "menu" :
            case "emergent" :

              this.typeMenu(
                id, video, video_dynamic, audio,
                pictogram_name, pictogram_url, pictogram_url_hover,
                pictogram_onclick_url, pictogram_onclick_state, pictogram_emergent,
                $elem, corrections, position,
                custom_class, element_path, position_correction, type, posQuery
              );

              break;

            case "embedded" :

              this.typeEmbedded(
                id, video, audio,
                pictogram_name, pictogram_url, pictogram_url_hover,
                pictogram_onclick_url, pictogram_onclick_state,
                $elem, corrections, custom_class
              );

              break;

            case 'pageContent' :
              this.typePageContent(
                id,
                video,
                audio
              );
              break;

            default:
              break;
          }
        }

      } // Fin condicional de element_path y state

    } // Fin ciclo for

  }

  typeEmbedded( id, video, audio,
                pictogram_name, pictogram_url, pictogram_url_hover,
                pictogram_onclick_url, pictogram_onclick_state,
                $elem, corrections, custom_class ) {

    let {
          width,
          height
        } = corrections;

    width  = this.getCorrectionsWH( "width", width );
    height = this.getCorrectionsWH( "height", height );

    let idAudio = `pauAudioHots-${ id }`,
        idVideo = `pauVideoHots-${ id }`,
        idImg   = `pauImgHots-${ id }`,
        idCont  = `pauHotsContainer-${ id }`;

    let videoSources = this.getVideoSources( video ),
        audioSources = this.getAudioSources( audio ),
        videoCont    = videoSources !== "" ? `
                <video playsinline id="${ idVideo }" class="pauHotsContAideo ${ custom_class }" loop="true">
                    ${videoSources}Your browser does not support the video tag html5
                </video>` : "",
        audioCont    = audioSources !== "" ? `
                <audio id="${ idAudio }" class="pauHotsContAudio">
                    ${ audioSources }Your browser does not support the audio tag html5
                </audio>` : "",

        imgTag = pictogram_url !== "" ? `<img id="${ idImg }" 
                                              class="pauPictoHotspot pau-m0" 
                                              style="${ width } ${ height }" 
                                              data-url-cambio-estado="${ this.addQAuto( pictogram_url_hover, true ) }" 
                                              src="${ this.addQAuto( pictogram_url, true ) }" 
                                              alt="${ pictogram_name }">` : "";

    let container = videoSources === "" && audioSources === "" && imgTag === "" ? "" : `
        <div id="${idCont}" class="pauHotsContainer" style="${ width } ${ height }">
            ${ imgTag }
            ${ videoCont }
            ${ audioCont }
        </div>`;

    if ( $elem.tagName === 'IMG' ) {
      $elem.addClass( "pauHotsAsigSelect" )
           .insertAdjacentHTML( 'afterend', container );
    } else {
      $elem.addClass( "pauHotsAsigSelect" )
           .insertAdjacentHTML( 'afterbegin', container );
    }

    /*
     * Pictograma OnClic
     * */

    this.setPictogramOnClick( $elem, pictogram_onclick_state, pictogram_onclick_url );

    let $elemCont = document.querySelector( `#${idCont}` );

    let actionMouseEnter = ( e ) => {

      // e.preventDefault();

      // if( typeof e.touches !== "undefined" && e.touches.length > 1 || ( e.type == "touchend" && e.touches.length > 0 ) )
      //     return;

      let settings = this.getSettings(),
          {
            geral_todo,
            geral_sonido,
            geral_video
          }        = settings;

      let $elemImg = document.querySelector( "#" + idImg );

      if ( imgTag !== "" ) {

        let srcOn  = $elemImg.getAttribute( "data-url-cambio-estado" ),
            srcOff = $elemImg.getAttribute( "src" );

        if ( srcOn !== "" && srcOff !== "" ) {
          $elemImg.setAttribute( "src", srcOn );
          $elemImg.setAttribute( "data-url-cambio-estado", srcOff );
        }
      }

      if ( !geral_todo.value ) return;

      let $elemVideo = document.querySelector( "#" + idVideo ),
          $elemAudio = document.querySelector( "#" + idAudio );

      $elemVideo.show();

      if ( videoSources !== "" && geral_video.value ) {

        this.thenCatchAutoplay( {
          elem: $elemVideo
        } );

      }

      if ( audioSources !== "" && geral_sonido.value ) {

        this.thenCatchAutoplay( {
          elem: $elemAudio
        } );

      }

      this.verifyActionMultimedia( this.videoRender, 'pause' );
      this.verifyActionMultimedia( this.audioRender, 'pause' );

    };

    let actionMouseLeave = e => {

      // e.preventDefault();

      let settings = this.getSettings(),
          {
            geral_todo,
            geral_sonido,
            geral_video,
            geral_lectura
          }        = settings;

      let $elemImg = document.querySelector( "#" + idImg );

      if ( imgTag !== "" ) {

        let srcOn  = $elemImg.getAttribute( "src" ),
            srcOff = $elemImg.getAttribute( "data-url-cambio-estado" );

        if ( srcOn !== "" && srcOff !== "" ) {
          $elemImg.setAttribute( "src", srcOff );
          $elemImg.setAttribute( "data-url-cambio-estado", srcOn );
        }

      }

      if ( !geral_todo.value ) return;

      let $elemVideo = document.querySelector( "#" + idVideo ),
          $elemAudio = document.querySelector( "#" + idAudio );

      $elemVideo.hide();

      if ( !geral_lectura.value ) return;

      if ( videoSources !== "" && geral_video.value ) {

        setTimeout( () => {
          $elemVideo.pause();
          $elemVideo.currentTime = 0;

          this.verifyActionMultimedia( this.videoRender, 'play' );
        }, 500 );

      }

      if ( audioSources !== "" && geral_sonido.value ) {

        setTimeout( () => {
          $elemAudio.pause();
          $elemAudio.currentTime = 0;

          this.verifyActionMultimedia( this.audioRender, 'play' );
        }, 500 );

      }

    };

    if ( videoSources === "" ) $elemCont.removeClass( "pauHotsContainer" );

    let eventInside = "mouseenter",
        eventOut    = "mouseleave";

    if ( isMobile ) {

      eventInside = "touchstart";
      eventOut    = "touchend";

      $elem.on( 'contextmenu', e => {
        return false;
      } );

    }

    $elem.on( eventInside, actionMouseEnter );
    $elem.on( eventOut, actionMouseLeave );

  }

  typeMenu(
    id, video, video_dynamic, audio,
    pictogram_name, pictogram_url, pictogram_url_hover,
    pictogram_onclick_url, pictogram_onclick_state, pictogram_emergent,
    $elem, corrections, position,
    custom_class, element_path, position_correction, type = 'menu',posQuery = ''
  ) {
    // se encarga de detener el hotspot
    const handleEventOut = ( e ) => {

      let settings = this.getSettings(),
        {
          geral_todo,
          geral_sonido,
          geral_video
        }  = settings;

      if ( !geral_todo.value ) return;

      let $elemVideo = document.querySelector( "#" + idVideo ),
        $elemAudio   = document.querySelector( "#" + idAudio ),
        $elemImg     = document.querySelector( "#" + idImg );

      if ( !svg ) {

        if ( imgTag !== "" ) {

          let srcOn  = $elemImg.getAttribute( "src" ),
            srcOff   = $elemImg.getAttribute( "data-url-cambio-estado" );

          if ( srcOn !== "" && srcOff !== "" ) {
            $elemImg.setAttribute( "src", srcOff );
            $elemImg.setAttribute( "data-url-cambio-estado", srcOn );
          }

        }

      }

      if ( videoSources !== "" && geral_video.value ) {
        if( pictogram_emergent !== 'active' && type === 'menu' ) {
          $elemVideo.removeClass( "video-activado" )
                    .addClass( "video-desactivado" )
                    .pause();
        }else {
          $elemVideo.pause();
        }

        $elemVideo.currentTime = 0;

        this.verifyActionMultimedia( this.videoRender, 'play' );

      }

      if ( audioSources !== "" && geral_sonido.value ) {

        if( pictogram_emergent !== 'active'  ) {
          $elemAudio.removeClass( "audio-activado" )
                    .addClass( "audio-desactivado" )
                    .pause();
        }else {
          $elemAudio.pause();
        }

        $elemAudio.currentTime = 0;

        this.verifyActionMultimedia( this.audioRender, 'play' );

      }
    };

    const handleEventInside = ( actualTooltip = null ) => {

      let settings = this.getSettings(),
        {
          geral_todo,
          geral_sonido,
          geral_video
        } = settings;

      if ( !geral_todo.value ) return;

      let $elemVideo = document.querySelector( "#" + idVideo ),
        $elemAudio = document.querySelector( "#" + idAudio ),
        $elemImg   = document.querySelector( "#" + idImg );

      if ( pictogram_emergent === 'active' && isEmptyNull( video_dynamic ) ) {
        $elemImg.addClass( 'pau-hotspot-emergent-active' );
      }

      /**
       * Verificando que estos valores
       * no sean números para asignarles un valor por defecto
       * y no genere error en el cálculo
       */
      if ( isNaN( x ) ) {
        x = 0;
      }
      if ( isNaN( y ) ) {
        y = 0;
      }

      if ( videoSources !== "" && geral_video.value ) {

        if( pictogram_emergent !== 'active' && type === 'menu' ) {
          $elemVideo.addClass( "video-activado" )
                    .removeClass( "video-desactivado" );
        }

        this.thenCatchAutoplay( {
          elem: $elemVideo
        } );

        this.verifyActionMultimedia( this.videoRender, 'pause' );
      }

      if ( audioSources !== "" && geral_sonido.value ) {

        if( pictogram_emergent !== 'active' && type === 'menu' ) {

          $elemAudio.addClass( "audio-activado" )
                    .removeClass( "audio-desactivado" );
        }

        this.thenCatchAutoplay( {
          elem: $elemAudio
        } );

        this.verifyActionMultimedia( this.audioRender, 'pause' );
      }

      if ( !svg ) {

        if ( imgTag !== "" ) {

          let srcOn  = $elemImg.getAttribute( "data-url-cambio-estado" ),
            srcOff   = $elemImg.getAttribute( "src" );

          if ( srcOn !== "" && srcOff !== "" ) {
            $elemImg.setAttribute( "src", srcOn );
            $elemImg.setAttribute( "data-url-cambio-estado", srcOff );
          }

        }
      }
    };

    let eventInside = "mouseenter",
        eventOut    = "mouseleave";

    if ( isMobile ) {
      eventInside = "touchstart";
      eventOut    = "touchend";

      $elem.on( 'contextmenu', e => {
        return false;
      } );

    } else {
      $elem.oncontextmenu = handleEventOut;
    }

    let {
          width,
          height,
          x,
          y,
          width_emergent,
          height_emergent
        } = corrections;

    width           = this.getCorrectionsWH( "width", width );
    height          = this.getCorrectionsWH( "height", height );
    width_emergent  = this.getCorrectionsWH( "width", width_emergent );
    height_emergent = this.getCorrectionsWH( "height", height_emergent );

    let idAudio = `pauAudioHots-${id}`,
        idVideo = `pauVideoHots-${id}`,
        idImg   = `pauImgHots-${id}`,
        imgTag  = "",
        svg     = false,
        $this   = this;

    let tagName = $elem.tagName.toLowerCase();

    if ( !isEmptyNull( pictogram_url ) ) {
      svg = /\.svg/.test( pictogram_url );
    }

    let videoSources = this.getVideoSources( video ),
        audioSources = this.getAudioSources( audio )

    let classVideoDesactivado = pictogram_emergent === 'active' ? '' : 'video-desactivado';
    let classAudioDesactivado = pictogram_emergent === 'active' ? '' : 'audio-desactivado';

        let videoTag     = videoSources !== "" ? `<video id="${ idVideo }"
                                                         playsinline
                                                         class="pau-videos ${ classVideoDesactivado } ${ custom_class }" 
                                                         style="${ pictogram_emergent !== 'active'? '' : 'position: initial !important' }" 
                                                         loop="true">
                                                      ${ videoSources }Your browser does not support the video tag html5
                                                  </video>` : "";

        let audioTag     = audioSources !== "" ? `<audio id="${idAudio}" 
                                                         class="pau-audios ${classAudioDesactivado}">
                                                      ${audioSources}Your browser does not support the audio tag html5
                                                  </audio>` : "";

    let pauPosition = `pauHotSpot${position.capitalize()}`;

    let classPitcoEmergent = '',
        correctionPosition = '';

    if ( pictogram_emergent === 'active' && isEmptyNull( video_dynamic ) ) {
      classPitcoEmergent = 'pau-hotspot-emergent';
    }

     imgTag = !isEmptyNull( pictogram_url ) ? `<img id="${idImg}" 
                                                    class="pauPictoHotspot ${ classPitcoEmergent }"
                                                    data-url-cambio-estado="${ this.addQAuto( pictogram_url_hover, true ) }"
                                                    src="${ this.addQAuto( pictogram_url, true ) }"
                                                    alt="${ pictogram_name }">` : "";

    let typeContainer = this.getTypeContainer( {
      id,
      type: 'menu',
      vals: videoTag + audioTag + imgTag
    } );

    if ( svg ) {

      this.svgRender(
        pictogram_url, idImg, width, height,
        $this, id, videoTag, audioTag, $elem,
        pauPosition, position, type , element_path,
        classPitcoEmergent, correctionPosition,
        pictogram_emergent, eventInside , eventOut,
        handleEventInside, handleEventOut, x, y, corrections, posQuery
      );

    } else {

      let imgTag = !isEmptyNull( pictogram_url ) ? `<img id="${idImg}" 
                                                         class="pauPictoHotspot ${ classPitcoEmergent }"
                                                         data-url-cambio-estado="${ this.addQAuto( pictogram_url_hover, true ) }"
                                                         src="${ this.addQAuto( pictogram_url, true ) }"
                                                         alt="${ pictogram_name }">` : "";

      let typeContainer = this.getTypeContainer( {
        id,
        type: 'menu',
        vals: videoTag + audioTag + imgTag,
        width_emergent,
        height_emergent
      } );

      let contentTippy = `<div class="pau-wrapper-${id} ${pauPosition}">
                             ${typeContainer}
                           </div>`;

      if ( pictogram_emergent !== 'active' && type === 'menu' && !isEmptyNull( pictogram_url )  ) {

        $this.typeEmbeddedAbsolute( $elem, corrections, contentTippy, id, position, x, y, eventInside, handleEventInside, eventOut, handleEventOut );
        //$this.typeEmbeddedTooltip( $elem, corrections, position, contentTippy, x, y, eventInside, handleEventInside, eventOut, handleEventOut);
        return;
      } else {
        $this.typeEmergentTooltip( $elem, contentTippy, position, x, y, handleEventInside, handleEventOut );
        return;
      }

    }

    if ( $elem.length > 1 && $elem.firstElementChild.tagName === 'svg' ) {
      $elem = $elem.firstElementChild.parentElement;
    }

    /*
     * Pictograma OnClic
     * */
    this.setPictogramOnClick( $elem, pictogram_onclick_state, pictogram_onclick_url );

  }

  getWidth( $elem ) {

    let actionDisplay = false,
        parentElement;

    if ( $elem ) {

      if ( !$elem.checkVisibility() ) {

        const parents = $elem.parents();

        for ( const parent of parents ) {

          if ( getComputedStyle( parent ).display === 'none' ) {
            parentElement = parent;
            parentElement.css( {
              display: 'block'
            });
            actionDisplay = true;
            break;
          }

        }

      }

      let width = $elem instanceof SVGElement
        ? $elem.getBoundingClientRect().width
        : $elem.offsetWidth;

      if ( actionDisplay ) {
        parentElement.css( {
          "display": 'none'
        });
      }

      return width;

    }

  }

  typeEmergent( id, video, audio,
                pictogram_name, pictogram_url, pictogram_url_hover,
                pictogram_onclick_url, pictogram_onclick_state,
                $elem, corrections, position,
                custom_class ) {

    let {
          width,
          height,
          x,
          y,
          width_emergent,
          height_emergent
        } = corrections;

    width = this.getCorrectionsWH( "width", width, 'img' );
    height = this.getCorrectionsWH( "height", height, 'img' );
    width_emergent = this.getCorrectionsWH( "width", width_emergent, 'video' );
    height_emergent = this.getCorrectionsWH( "height", height_emergent, 'video' );

    let idAudio = `pauAudioHots-${id}`,
        idVideo = `pauVideoHots-${id}`,
        idImg   = `pauImgHots-${id}`;

    let videoSources = this.getVideoSources( video ),
        audioSources = this.getAudioSources( audio ),

        videoTag     = videoSources !== "" ? `
                <video playsinline id="${idVideo}" class="pau-videos video-desactivado ${custom_class}" style="${width_emergent} ${height_emergent}" loop="true">
                    ${videoSources}Your browser does not support the video tag html5
                </video>` : "",

        audioTag     = audioSources !== "" ? `
                <audio id="${idAudio}" class="pau-audios audio-desactivado">
                    ${audioSources}Your browser does not support the audio tag html5
                </audio>` : "",

        imgTag       = pictogram_url !== "" ? `
                <img id="${idImg}" class="pauPictoHotspot" style="${width} ${height}" data-url-cambio-estado="${this.addQAuto(pictogram_url_hover, true)}" src="${this.addQAuto( pictogram_url,true )}" alt="${pictogram_name}">` : "";

    let clase = `pauHotSpot${position.capitalize()}`;

    let typeContainer = this.getTypeContainer( {
      id,
      type: 'menu',
      clase,
      vals: videoTag + audioTag + imgTag
    } );

    if ( position !== "none" ) {

      let clase = `pauHotSpot${position.capitalize()}`;

      $elem.css( {
        "position"   : 'relative',
        "display"    : 'flex',
        "align-items": 'center',
      } ).addClass( clase );

    }

    /*
     * Pictograma OnClic
     * */

    this.setPictogramOnClick( $elem, pictogram_onclick_state, pictogram_onclick_url );

    if ( $elem.tagName === 'IMG' ) {
      $elem.insertAdjacentHTML( 'afterend', typeContainer );
    } else {
      $elem
        .css( "position", "relative" )
        .insertAdjacentHTML( 'afterbegin', typeContainer );
    }

    let eventInside = "mouseenter",
        eventOut    = "mouseleave";

    if ( isMobile ) {

      eventInside = "touchstart";
      eventOut = "touchend";

      $elem.on( 'contextmenu', e => {
        return false;
      } );

    }

    $elem.on( eventInside, ( e ) => {

      // e.preventDefault();

      // if( typeof e.touches !== "undefined" && e.touches.length > 1 || ( e.type == "touchend" && e.touches.length > 0 ) )
      //     return;

      // let pos = e.target.getBoundingClientRect();

      let settings = this.getSettings(),
          {
            geral_todo,
            geral_sonido,
            geral_video
          }        = settings;

      if ( !geral_todo.value ) return;

      let $elemVideo = document.querySelector( "#" + idVideo ),
          $elemAudio = document.querySelector( "#" + idAudio ),
          $elemImg   = document.querySelector( "#" + idImg );

      /**
       * Verificando que estos valores
       * no sean números para asignarles un valor por defecto
       * y no genere error en el cálculo
       */
      if ( isNaN( x ) ) {
        x = 0;
      }
      if ( isNaN( y ) ) {
        y = 0;
      }

      if ( videoSources !== "" && geral_video.value ) {

        $elemVideo.css( "left", x );
        $elemVideo.css( "top", y );

        $elemVideo
          .addClass( "video-activado" )
          .removeClass( "video-desactivado" );

        this.thenCatchAutoplay( {
          elem: $elemVideo
        } );

        if ( !this.videoRender.ended ) this.videoRender.pause();

      }

      if ( audioSources !== "" && geral_sonido.value ) {

        $elemAudio
          .addClass( "audio-activado" )
          .removeClass( "audio-desactivado" );

        this.thenCatchAutoplay( {
          elem: $elemAudio
        } );

        this.verifyActionMultimedia( this.audioRender, 'pause' );

      }

      if ( imgTag !== "" ) {

        let srcOn  = $elemImg.getAttribute( "data-url-cambio-estado" ),
            srcOff = $elemImg.getAttribute( "src" );

        if ( srcOn !== "" && srcOff !== "" ) {
          $elemImg.setAttribute( "src", srcOn );
          $elemImg.setAttribute( "data-url-cambio-estado", srcOff );
        }

      }

    } );

    $elem.on( eventOut, ( e ) => {

      let settings = this.getSettings(),
          {
            geral_todo,
            geral_sonido,
            geral_video
          }        = settings;

      if ( !geral_todo.value ) return;

      let $elemVideo = document.querySelector( "#" + idVideo ),
          $elemAudio = document.querySelector( "#" + idAudio ),
          $elemImg   = document.querySelector( "#" + idImg );

      if ( imgTag !== "" ) {

        let srcOn  = $elemImg.getAttribute( "src" ),
            srcOff = $elemImg.getAttribute( "data-url-cambio-estado" );

        if ( srcOn !== "" && srcOff !== "" ) {
          $elemImg.setAttribute( "src", srcOff );
          $elemImg.setAttribute( "data-url-cambio-estado", srcOn );
        }

      }

      if ( videoSources !== "" && geral_video.value ) {

        setTimeout( () => {
          $elemVideo
            .removeClass( "video-activado" )
            .addClass( "video-desactivado" )
            .pause();

          $elemVideo.currentTime = 0;

          this.verifyActionMultimedia( this.videoRender, 'play' );
        }, 500 );

      }

      if ( audioSources !== "" && geral_sonido.value ) {

        setTimeout( () => {
          $elemAudio
            .removeClass( "audio-activado" )
            .addClass( "audio-desactivado" )
            .pause();

          $elemAudio.currentTime = 0;

          this.verifyActionMultimedia( this.audioRender, 'play' );
        })

      }

    } );

  }

  verifyActionMultimedia( $elem, state ) {

    if ( $elem.hasChildNodes() ) {

      switch ( state ) {

        case "play":
          if (
            $elem.getState() === 'paused' &&
            !$elem.ended
          ) {
            $elem.play();
            $elem.setState( 'playing' );
          }
          break;

        case "pause":
          if (
            $elem.getState() === 'playing' &&
            !$elem.ended
          ) {
            $elem.pause();
            $elem.setState( 'paused' );
          }
          break;

      }

    }

  }

  initialStateMultimedia( $elem ) {

    let state = 'stop';

    const states = [
      'stop',
      'playing',
      'paused',
    ];

    Object.defineProperty( $elem, 'getState', {
      enumerable  : false,
      writable    : false,
      configurable: true,
      value() {
        return state;
      }
    } );

    Object.defineProperty( $elem, 'setState', {
      enumerable  : false,
      writable    : false,
      configurable: true,
      value( newState ) {
        if ( states.includes( newState ) ) {
          state = newState;
        }
      }
    } );

  }

  typePageContent( id, video, audio ) {

    this.pauReactPageVideo.innerHTML = `${this.getVideoSources( video )}`;
    this.pauReactPageAudio.innerHTML = `${this.getAudioSources( audio )}`;

    this.videoRender.load();
    this.initialStateMultimedia( this.videoRender );
    this.pauReactLocucionLSE.firstElementChild
      .setAttribute( "id", `pau-hotspot-${id}` );
    this.pauReactLocucionLSE.show();
    if ( this.audioRender.hasChildNodes() ) {
      this.audioRender.load();
      this.initialStateMultimedia( this.audioRender );
    }

    let settings = this.getSettings(),
        {
          geral_todo,
          geral_lectura,
          geral_sonido,
          geral_video
        }        = settings;

    if ( !geral_todo.value ) return;
    if ( !geral_lectura.value ) return;

    if ( geral_video.value ) {

      let $reactLocuLSE = this.pauReactLocucionLSE;

    }

    // Acciones de los botones para el contenedor
    // de video de página

    let $pauReactPlayVideo        = document.querySelector( "#pauReactPlayVideo" ),
        $pauReactPantallaCompleta = document.querySelector( "#pauReactPantallaCompleta" ),
        $pauReactCierraVideo      = document.querySelector( "#pauReactCierraVideo" );

    $pauReactPantallaCompleta.addEventListener( "click", () => {
      this.pauFullScreen( this.videoRender );
    } );

    $pauReactPlayVideo.addEventListener( "click", () => {
      this.pauVideoPlayPause( this.audioRender, this.videoRender, $pauReactPlayVideo );
    } );

    this.pauReactPageVideo.addEventListener( "click", () => {
      this.pauVideoPlayPause( this.audioRender, this.videoRender, $pauReactPlayVideo );
    } );

    $pauReactCierraVideo.addEventListener( "click", () => {
      this.pauReactLocucionLSE.style.display = 'none';
      this.videoRender.pause();
      this.videoRender.currentTime = 0;

    } );

  }

  svgRender(
    pictogram_url, idImg, width, height,
    $this, id, videoTag, audioTag, $elem,
    pauPosition, position= '', type, element_path,
    classPitcoEmergent, correctionPosition,
    pictogram_emergent, eventInside = '', eventOut = ''
    , handleEventInside = null, handleEventOut = null, x = 0, y = 0, corrections,posQuery = ''
  ) {


    fetch( pictogram_url )
      .then( ( res ) => {
        return res.blob();
      } )
      .then( ( blob ) => {

        let lector = new FileReader();

        lector.onload = function ( e ) {

          let contenido = e.target.result;

          // viewBox="0 0 60 55"
          let $svg = new DOMParser().parseFromString( contenido, 'text/html' ).body.firstChild;
          let svgTag = `
							<div id="${idImg}" class="pauPictoHotspot pauSvgContainer ${classPitcoEmergent}" >
								<svg  class="pauPictoHotspotSvg" id="${$svg.getAttribute( 'id' ) || ''}" viewBox="${$svg.getAttribute( 'viewBox' )}">
									 ${$svg.innerHTML}
								</svg>
							</div>`;

          let typeContainer = $this.getTypeContainer( {
            id,
            type,
            vals: videoTag + audioTag + svgTag,
            width_emergent : width,
            height_emergent : height

          } );

          if ( $elem.tagName === 'SVG' ) {

            $elem = document.querySelectorAll( element_path );

              if ( $elem.length > 1 ) {
                if( !isEmptyNull( posQuery ) ){
                  $elem = $elem[ posQuery ];
                }else{
                  $elem = $elem[ 0 ];
                }
              }

            if ( $elem.length > 1 ) {
                  $elem = $elem[ 0 ];

            }
          }

          if ( pictogram_emergent !== 'active' && type === 'menu' && !isEmptyNull( pictogram_url )  ) {
            $this.typeEmbeddedAbsolute( $elem, corrections, typeContainer, id, position, x, y, eventInside, handleEventInside, eventOut, handleEventOut );
            //$this.typeEmbeddedTooltip( $elem, corrections, position, typeContainer, x, y, eventInside, handleEventInside, eventOut, handleEventOut);
            return;
          } else {
            $this.typeEmergentTooltip( $elem, typeContainer , position, x, y, handleEventInside, handleEventOut);
            return;
          }

            /*$this.insertTooltip( pictogram_emergent, type, $this,
              $elem, corrections, id, position, typeContainer, x, y,
              eventInside, handleEventInside, eventOut, handleEventOut, pictogram_url);*/

        };

        lector.readAsText( blob );

      } );
  }



  getTypeContainer( { id = "", type = 'menu', vals = '', width_emergent = '', height_emergent = '' } ) {

    return `<div class="pau-contenedor pau-container-${type} pau-container-${type}-${id}">
              <div class=´pau-container-style´ style="${width_emergent} ${height_emergent}" >
                  ${vals}					
              </div>
            </div>`;
  }

  getCorrectionsWH( tag, val ) {
    return val !== "" && isNumeric( val ) ? `${tag}: ${val}rem!important; max-${tag}: ${val}rem!important;` : "";
  }

  getCorrectionPosition( tag, val ) {
    return val !== "" && isNumeric( val ) ? `${tag}: ${val}rem!important;` : "";
  }

  pauVideoPlayPause( audio, video, btn ) {

    if ( audio.hasChildNodes() ) {
      if ( audio.getState() === 'stop' ) {
        audio.play();
        audio.setState( 'playing' );
      } else {
        audio.pause();
        audio.setState( 'stop' );
      }

    }

    if ( video.paused ) {
      if ( this.isMsgInitLSE ) {
        document.querySelector( '#msgInitLSE' ).style.display = 'none';
        this.isMsgInitLSE = false;
      }
      video.play();
      video.setState( 'playing' );
      btn.innerHTML = 'Pause <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M272 63.1l-32 0c-26.51 0-48 21.49-48 47.1v288c0 26.51 21.49 48 48 48L272 448c26.51 0 48-21.49 48-48v-288C320 85.49 298.5 63.1 272 63.1zM80 63.1l-32 0c-26.51 0-48 21.49-48 48v288C0 426.5 21.49 448 48 448l32 0c26.51 0 48-21.49 48-48v-288C128 85.49 106.5 63.1 80 63.1z"/></svg>';
    } else {
      video.pause();
      video.setState( 'stop' );
      btn.innerHTML = 'Play <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M361 215C375.3 223.8 384 239.3 384 256C384 272.7 375.3 288.2 361 296.1L73.03 472.1C58.21 482 39.66 482.4 24.52 473.9C9.377 465.4 0 449.4 0 432V80C0 62.64 9.377 46.63 24.52 38.13C39.66 29.64 58.21 29.99 73.03 39.04L361 215z"/></svg>';
    }

  }

  pauFullScreen( elem ) {

    if ( elem.requestFullScreen ) {
      elem.requestFullScreen();
    } else if ( elem.webkitRequestFullScreen ) {
      elem.webkitRequestFullScreen();
    } else if ( elem.mozRequestFullScreen ) {
      elem.mozRequestFullScreen();
    } else if ( elem.msRequestFullscreen ) {
      elem.msRequestFullscreen();
    }

  }

  pauFullScreenCancel( elem ) {

    if ( document.exitFullScreen ) {
      document.exitFullScreen();
    } else if ( document.webkitExitFullscreen ) {
      document.webkitExitFullscreen();
    } else if ( document.mozCancelFullScreen ) {
      document.mozCancelFullScreen();
    } else if ( document.msExitFullscreen ) {
      document.msExitFullscreen();
    }

  }

  thenCatchAutoplay( { elem, success = null, fail = null } ) {

    let promise = elem.play();

    if ( promise !== undefined ) {

      promise.then( _ => {

        if ( success && typeof success === "function" ) success();

      } ).catch( error => {

        if ( fail && typeof fail === "function" ) fail( error );

      } );

    }

  }

  clickStateForAutoplay( { video = null, audio = null, callback = null } ) {

    let settings         = this.getSettings(),
        { geral_sonido } = settings;

    let count = 1;

    document.on( "click", ( e ) => {

      if ( count !== 1 ) return;

      count++;

      if ( video ) {

        let videoRender = video.play();

        if ( videoRender !== undefined ) {

          videoRender.then( _ => {

            if ( callback && typeof callback === "function" ) callback();

          } ).catch( error => {
            // if( fail && typeof fail === "function" ) fail();
          } );

        }

      }

      if ( audio ) {

        if ( geral_sonido.value ) {

          let audioRender = audio.play();

          if ( audioRender !== undefined ) {

            audioRender.then( _ => {

            } ).catch( error => {
              console.log( error );
            } );

          }

        }

      }

    } );

  }

  setPictogramOnClick( $elem, pictogram_onclick_state, pictogram_onclick_url ) {
    if ( pictogram_onclick_state === 'active' ) {
      $elem.on( 'click', function () {
        window.open( pictogram_onclick_url, "_blank" );
      } );
    }
  }

  getSettings() {
    return JSON.parse( Cookies.get( "settings" ) );
  }

}

export default ManagerHotspot;
