import anime from "animejs";
import { extendsObj } from "../constants/extendsObj";

String.prototype.capitalize = function () {
  return this.at( 0 ).toUpperCase() + this.substring( 1 );
}

String.prototype.camelCase = function () {
  return this.replace(/[-_\s]+(.)?/g, (match, c) => c ? c.toUpperCase() : '');
}

String.prototype.kebabCase = function () {
  return this.includes( '_' )
    ? this.replace( /_/g, '-' )
    : this.replace( /([a-z])([A-Z])/g, '$1-$2' ).toLowerCase();
}

String.prototype.snakeCase = function () {
  return this.includes( '-' )
    ? this.replace( /-/g, '_' )
    : this.replace( /([a-z])([A-Z])/g, '$1_$2' ).toLowerCase();
}
/**
 * HTMLElement Helpers
 * */
HTMLElement.prototype.empty = function () {
  this.innerHTML = '';
  return this;
}

SVGElement.prototype.empty = HTMLElement.prototype.empty;

HTMLElement.prototype.show = function ( value = 'block' ) {
  this.style.display = value;
  return this;
}

SVGElement.prototype.show = HTMLElement.prototype.show;

HTMLElement.prototype.hide = function () {
  this.style.display = 'none';
  return this;
}

SVGElement.prototype.hide = HTMLElement.prototype.hide;

HTMLElement.prototype.css = function ( name, value ) {

  if ( name !== null && typeof name === 'object' ) {

    for ( let style in name ) {
      this.style[ style.camelCase() ] = name[ style ];
    }

  } else {
    this.style[ name.camelCase() ] = value;
  }

  return this;

}

SVGElement.prototype.css = HTMLElement.prototype.css;

HTMLElement.prototype.parents = function () {
  const parents = [];
  let parent = this.parentNode;
  while ( parent && parent.nodeName !== 'BODY' ) {
    parents.push( parent );
    parent = parent.parentNode;
  }
  return parents;
}

SVGElement.prototype.parents = HTMLElement.prototype.parents;

HTMLElement.prototype.addClass = function ( ...classes ) {
    this.classList.add( ...classes );
    return this;
}

/*let properties = {
  'addClass' : function ( ...classes ) {
    this.classList.add( ...classes );
    return this;
  }
};

for ( let prop in properties ) {
  HTMLCollection.prototype[ prop ] = properties[ prop ];
}*/

Node.prototype.addClass = SVGElement.prototype.addClass = HTMLElement.prototype.addClass;

HTMLElement.prototype.removeClass = function ( ...classes ) {
    this.classList.remove( ...classes );
    return this;
}

Node.prototype.removeClass = SVGElement.prototype.removeClass = HTMLElement.prototype.removeClass;

HTMLElement.prototype.trigger = function ( eventType ) {
  const event = new Event( eventType );
  this.dispatchEvent( event );
  return this;
}

SVGElement.prototype.trigger = HTMLElement.prototype.trigger;

HTMLElement.prototype.not = function ( selector ) {
  if ( ! this.matches( selector ) ) {
    return this;
  }
  return document.createElement( 'div' );
}

Node.prototype.not = SVGElement.prototype.not = HTMLElement.prototype.not;

/**
 * Animations
 */

HTMLElement.prototype.fadeIn = function(duration = 500) {

  this.css({
    opacity: '0',
    display: 'block',
    transition: `opacity ${duration}ms ease-in-out`,
  });

  setTimeout(() => {
    this.style.opacity = '1';
  }, 1);

  return this;

}

SVGElement.prototype.fadeIn = HTMLElement.prototype.fadeIn;

HTMLElement.prototype.fadeOut = function(duration = 500) {

  this.css({
    opacity: '1',
    transition: `opacity ${duration}ms ease-in-out`,
  }).style.opacity = '0';

  setTimeout(() => {
    this.css({
      display: 'none',
      opacity: 1
    })
  }, duration);

  return this;

}

SVGElement.prototype.fadeOut = HTMLElement.prototype.fadeOut;

HTMLElement.prototype.fadeToggle = function(duration = 500) {

  if (this.style.opacity === '0') {
    this.fadeIn(duration);
  } else {
    this.fadeOut(duration);
  }

  return this;

}

SVGElement.prototype.fadeToggle = HTMLElement.prototype.fadeToggle;

HTMLElement.prototype.anime = function ( properties ) {

  for ( let prop in properties ) {
    if ( prop.includes( '-' ) ) {
      properties[ prop.camelCase() ] = properties[ prop ];
      delete properties[ prop ];
    }
  }

  const propDefaults = {
    targets: this,
    duration: 500,
    easing: 'easeInOutQuad'
  }

  anime( extendsObj( propDefaults, properties  ) );

  return this;

}

Node.prototype.anime = SVGElement.prototype.anime = HTMLElement.prototype.anime;

HTMLElement.prototype.on = function ( events, callback, useCapture = false ) {
  let arrayEvents = events.split( " " );

  arrayEvents.forEach( event => {
    this.addEventListener( event, callback, useCapture );
  } );

  return this;
}

HTMLElement.prototype.off = function ( event, callback, useCapture = false ) {
  this.removeEventListener( event, callback, useCapture );
  return this;
}

SVGElement.prototype.on = HTMLElement.prototype.on;
SVGElement.prototype.off = HTMLElement.prototype.off;
/**
 * HTMLCollection
 * NodeList
 */

HTMLCollection.prototype.forEach = Array.prototype.forEach;

HTMLCollection.prototype.addClass = function ( ...classes ) {
  this.forEach( ( element ) => {
    if ( typeof element === 'object' && element.nodeType === Node.ELEMENT_NODE ) {
      element.addClass( ...classes );
    }
  } );
  return this;
}

Array.prototype.addClass = NodeList.prototype.addClass = HTMLCollection.prototype.addClass;

HTMLCollection.prototype.removeClass = function ( ...classes ) {
  this.forEach( ( element ) => {
    if ( typeof element === 'object' && element.nodeType === Node.ELEMENT_NODE ) {
      element.removeClass( ...classes );
    }
  } );
  return this;
}

Array.prototype.removeClass = NodeList.prototype.removeClass = HTMLCollection.prototype.removeClass;

HTMLElement.prototype.toggleClass = function ( classe, force ) {
  this.classList.toggle( classe, force );
  return this;
}

HTMLCollection.prototype.toggleClass = function ( classe, force ) {
  this.forEach( ( element ) => {
    if ( typeof element === 'object' && element.nodeType === Node.ELEMENT_NODE ) {
      element.toggleClass( classe, force );
    }
  } );
  return this;
}

Array.prototype.toggleClass = NodeList.prototype.toggleClass = HTMLCollection.prototype.toggleClass;

HTMLElement.prototype.hasClass = function ( classe ) {
  this.classList.contains( classe );
  return this;
}

HTMLElement.prototype.replaceClass = function ( oldClass, newClass ) {
  this.classList.replace( oldClass, newClass );
  return this;
}

HTMLCollection.prototype.replaceClass = function ( oldClass, newClass ) {
  this.forEach( ( element ) => {
    if ( typeof element === 'object' && element.nodeType === Node.ELEMENT_NODE ) {
      element.replaceClass( oldClass, newClass );
    }
  } );
  return this;
}

Array.prototype.replaceClass = NodeList.prototype.replaceClass = HTMLCollection.prototype.replaceClass;

HTMLCollection.prototype.fadeIn = function ( duration = 500 ) {
  this.forEach( element => {
    element.fadeIn( duration );
  });
  return this;
}

NodeList.prototype.fadeIn = HTMLCollection.prototype.fadeIn;

HTMLCollection.prototype.fadeOut = function ( duration = 500 ) {
  this.forEach( element => {
    element.fadeOut( duration );
  });
  return this;
}

NodeList.prototype.fadeOut = HTMLCollection.prototype.fadeOut;

HTMLCollection.prototype.fadeToggle = function ( duration = 500 ) {
  this.forEach( element => {
    element.fadeToggle( duration );
  });
  return this;
}

NodeList.prototype.fadeToggle = HTMLCollection.prototype.fadeToggle;

HTMLCollection.prototype.hide = function () {
  this.forEach( ( element ) => {
    element.hide();
  });
  return this;
}

NodeList.prototype.hide = HTMLCollection.prototype.hide;

HTMLCollection.prototype.show = function () {
  this.forEach( ( element ) => {
    element.show();
  });
  return this;
}

NodeList.prototype.show = HTMLCollection.prototype.show;

HTMLCollection.prototype.map = Array.prototype.map;
NodeList.prototype.map = Array.prototype.map;

HTMLCollection.prototype.on = function ( event, callback, useCapture = false ) {
  this.forEach( ( element ) => {
    element.addEventListener( event, callback, useCapture );
  });
  return this;
}

NodeList.prototype.on = HTMLCollection.prototype.on;

HTMLCollection.prototype.off = function ( event, callback, useCapture = false ) {
  this.forEach( ( element ) => {
    element.removeEventListener( event, callback, useCapture );
  });
  return this;
}

NodeList.prototype.off = HTMLCollection.prototype.off;

function parentElement() {
  return this.map( (el) => el.parentElement );
}

Object.defineProperty( HTMLCollection.prototype, 'parentElement', {
  get: parentElement
})

Object.defineProperty( NodeList.prototype, 'parentElement', {
  get: parentElement
})
