import './styles.css';
const FocusPanelButton = () => {
    //agregar los eventos al botón
    const focusPauAccessElm = ( e ) => {
        e.preventDefault();
        if ( ( e.type === 'keypress' && e.key === 'Enter' ) || e.type === 'click' ) {
            let firstElementFocus = document.querySelector('.pauAccessMenu > nav > ul > li > a');
            if ( firstElementFocus !== document.activeElement ) {
                firstElementFocus.focus();
            }
        }
    }
    return (
        <button
            tabIndex={ 1 }
            className={ 'focusPanelButton' }
            onKeyPress={ focusPauAccessElm }
            onClick={ focusPauAccessElm }
        >
            Saltar al panel PAU
        </button>
    )
}
export default FocusPanelButton
