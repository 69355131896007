import { extendsObj } from "../constants/extendsObj";
import { paulog } from "../constants/paulog";
import prettyFormat from "@permafrost-dev/pretty-format";

const fetchSetup = ( () => {

  let defaultOption = {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${global.accessToken}`,
      'token'        : global.license,
      'Content-Type' : 'application/json'
    },
    body: JSON.stringify({
      'pathName'     : location.pathname,
      'langCode'    : document.querySelector( 'html' ).getAttribute( 'lang' )
    })
  };

  return async ( url, callback, options = {} ) => {

    let initOptions = extendsObj( defaultOption, options );

    return await fetch( url, initOptions )
      .then( resp => {
        return resp.json();
      } )
      .then( ( data ) => {

        if ( data.status === 'error' ) {
          throw new Error( `Comunicación con la API. Tipo de error: [${data.error}]. Mensaje: ${data.message}` );
        }
        callback( data );
      } )
      .catch( ( error ) => {
        paulog(error)
      } );

  }

})();

export default fetchSetup;
