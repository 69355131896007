import React, { Component } from 'react';
// import 'antd/dist/antd.min.css';
import './../antd.css';
import './../App.css';
import CreateSection from "./CreateSection";
import { LoadingOutlined } from '@ant-design/icons';
import update from 'immutability-helper';
import ManagerHotspot from './../includes/ManagerHotspot';
import contenedorVideo from './../constants/contenedorVideo';
import { instanceOf } from 'prop-types';
import { Cookies, withCookies } from 'react-cookie';
import AccesibilityActions from './AccesibilityActions';
import { eventClickPauActions } from "../menuPauPrincipal/js/MenuPauPrincipal";
import { isNumeric } from "../constants/isNumeric";
import fetchX from "./fetchSetup";

document.body.insertAdjacentHTML( 'beforeend', contenedorVideo );

class Main extends Component {

  static propTypes = {
    cookies: instanceOf( Cookies ).isRequired
  };

  constructor( props ) {

    super( props );

    const { cookies, fetchUrls, settings } = props;

    this.state = {
      // settings         : cookies.get( 'settings' ) || null,
      shows            : null,
      diseno           : null,
      hotspots         : null,
      ico_pau          : null,
      ico_principal_pau: null,
      ico_ready        : null,
      settings
    };



    this.sections = null;
    this.fetchUrls = fetchUrls;

    AccesibilityActions.sonidoClick = document.createElement( 'audio' );
    AccesibilityActions.sonidoClick.src = `${global.urlBase}cloudpau/assets/pau/audios/ClickOn.mp3`;

    let coockieSettings = cookies.get( 'settings' ) || null;
    if ( !coockieSettings ) {
      cookies.set( 'settings', settings, { path: '/' } );
    }

    if ( settings ) {

      AccesibilityActions.initActions( {
        settings
      } );

    }

  };

  spinLoader() {
    return <LoadingOutlined
      style={{ fontSize: 24 }}
      spin/>;
  }

  UNSAFE_componentWillMount() {

    this.execDiseno();
    this.execShows();

  }

  componentDidMount() {

    let mediaDirHeader    = global.urlBase + "cloudpau/assets/pau/header/",
        ico_principal_pau = mediaDirHeader + "ico-principal-pau.svg",
        ico_pau           = mediaDirHeader + "ico-pau.svg";

    fetch( ico_principal_pau )
      .then( res => res.blob() )
      .then( blob => this.fileReader( 'ico_principal_pau', blob ) );

    setTimeout( () => {
      fetch( ico_pau )
        .then( res => res.blob() )
        .then( blob => this.fileReader( 'ico_pau', blob ) );
    }, 500 );

  }

  fileReader( type, blob ) {

    let lector = new FileReader();

    lector.onload = ( e ) => {
      let contenido = e.target.result,
          change    = {};
      change[ type ] = { $set: contenido };

      this.setState( update( this.state, change ) );
    };

    lector.readAsText( blob );

  }

  componentDidUpdate() {

    if ( this.state.ico_pau && this.state.ico_principal_pau ) {

      if ( !this.state.ico_ready ) {

        document.querySelector( '.iconPauAccess' ).innerHTML = this.state.ico_principal_pau;

        global.pauIconsMain = {
          ico_pau          : this.state.ico_pau,
          ico_principal_pau: this.state.ico_principal_pau
        };
        document.addEventListener( 'click', eventClickPauActions );

        this.setState( update( this.state, {
          ico_ready: { $set: true }
        } ) );

      }

    }

  }

  getValues( setting, key ) {

    if ( !setting ) return false;

    if ( setting.hasOwnProperty( key ) ) {

      let $key = setting[ key ];

      if ( typeof setting === "undefined" ) return false;

      if ( $key === "on" ) {
        return true;
      } else if ( isNumeric( $key ) ) {
        return parseInt( $key, 10 );
      }

    }

    return false;

  }

  getSettings( settings ) {
    return this.props.getSettings( settings );
  }

  execDiseno() {

    fetchX( this.fetchUrls.urlSettings, ( data ) => {

      const { settings } = data;
      const sets = this.getSettings( settings );

      if ( sets ) {
        AccesibilityActions.diseno( settings );
      }

      this.setState( update( this.state, {
        diseno: { $set: sets }
      } ) );

    } );

  }

  execShows() {

    fetchX( this.fetchUrls.urlSettings, ( data ) => {

      const { settings } = data;
      const sets = this.getSettings( settings );

      this.setState( update( this.state, {
        shows: { $set: sets }
      } ) );

    } );

  }



  zoomMasMenos( type ) {
    this.props.zoomMasMenos( type );
  }
  completedMount(){
    return this.props.completedMount();
  }

  render() {

    const { diseno, shows } = this.state;
    const { settings } = this.props;

    return (
      diseno && settings && shows
    ) ?
        <CreateSection
        settings={settings}
        zoomMasMenos={this.zoomMasMenos.bind( this )}
        shows={shows}
        completedMount={this.completedMount.bind(this)}/> : this.spinLoader();

  }

}

export default withCookies( Main );
