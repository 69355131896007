import React, { Component } from 'react';
import update from 'immutability-helper';
import Main from './includes/Main';
import AccesibilityActions from './includes/AccesibilityActions';
import './App.css';
import { instanceOf } from 'prop-types';
import { Cookies, withCookies } from 'react-cookie';
import { pictosPanelHeader } from "./constants/pictosPanel";
import fetchX from "./includes/fetchSetup";
import { isNumeric } from "./constants/isNumeric";
import Modal from "./Components/Modal";
import FocusPanelButton from './Components/FocusPanelButton';
import Panel from "./Components/Panel";
import ManagerHotspot from './includes/ManagerHotspot';
import prettyFormat from "@permafrost-dev/pretty-format";
import {isMobile} from "react-device-detect";

class App extends Component {

  static propTypes = {
    cookies: instanceOf( Cookies ).isRequired
  };

  constructor( props ) {

    super( props );

    global.LicenseState = null;

    const { cookies } = props;


    let settings    = cookies.get( "settings" ) || null,
        pau_version = cookies.get( 'pau_version' ) || false,
        zoom_pau    = 100;


    if ( !pau_version ) {
      cookies.set( "pau_version", "1.0.0", { path: "/" } );
    }

    if ( settings ) {
      zoom_pau = settings.zoom_pau.value;
    }

    let cookieFocusStyle = cookies.get( 'focusStyle' ) || null;

    if ( !cookieFocusStyle ) {
      cookies.set( 'focusStyle', {
        borderInner: '#000000',
        borderOuter: '#ffffff',
      }, { path: '/' } );
    }

    this.state = {
      zoom_pau,
      globals    : null,
      getLicense : null,
      licenseData: null,
      settings   : cookies.get( 'settings' ) || null,
      canPanelRendered       : false,
    };

    let urlSettings = `${global.urlApiBase}setting`,
        urlHotspots = `${global.urlApiBase}temporal-hotspots`,
        urlApi      = `${global.urlApiBase}licenseState`;

    this.fetchUrls = {
      urlSettings,
      urlHotspots,
      urlApi
    };

    this.apiPauCloud();

    AccesibilityActions.zoomPanel( zoom_pau,global.resize );
    this.managerHotspot = new ManagerHotspot();
    this.hotspotsAfterPanel = true

  }

  UNSAFE_componentWillMount() {

    if ( this.state.settings === null ) {
      this.execSettings();
    }
    this.execHotspots();

  }

  /**
   * Método que sirve para obtener los
   * valores de configuración mediante
   * la API de CloudPau
   */

  execSettings( reset = false ) {
    fetchX( this.fetchUrls.urlSettings, ( data ) => {

      const { settings } = data;
      let sets = this.getSettings( settings );
      const { cookies } = this.props;

      if ( sets ) {

        if ( reset ) {

          cookies.set( 'noPersonalizado', 1, { path: "/" } );
          AccesibilityActions.applyReset( sets, cookies );
          this.applyZoomPau( sets.zoom_pau.value, cookies, sets );
          cookies.set( 'noPersonalizado', 0, { path: "/" } );

        } else {

          AccesibilityActions.initActions( {
            settings: sets
          } );

        }

      }

      this.setState( update( this.state, {
        settings: { $set: sets },
        // sections : { $set : this.getSections( sets ) }
      } ) );

    } );

  }

  getSettings( settings ) {

    // Perfiles

    const perfil_defecto = {
      value: settings.cg_perfil_defecto.value,
      show : settings.cg_perfil_defecto.show || false
    };

    // General

    const geral_todo = {
      value: this.getValues( settings.cg_geral_todo, "value" ),
      show : this.getValues( settings.cg_geral_todo, "show" )
    };

    const geral_sonido = {
      value: this.getValues( settings.cg_geral_sonido, "value" ),
      show : this.getValues( settings.cg_geral_sonido, "show" )
    };

    const geral_video = {
      value: this.getValues( settings.cg_geral_video, "value" ),
      show : this.getValues( settings.cg_geral_video, "show" )
    };

    const geral_lectura = {
      value: this.getValues( settings.cg_geral_lectura, "value" ),
      show : this.getValues( settings.cg_geral_lectura, "show" )
    };

    // Navegación

    const nav_bigcursor = {
      value: this.getValues( settings.cg_nav_bigcursor, "value" ),
      show : this.getValues( settings.cg_nav_bigcursor, "show" )
    };

    const nav_clic = {
      value: this.getValues( settings.cg_nav_clic, "value" ),
      show : this.getValues( settings.cg_nav_clic, "show" )
    };

    /*const nav_donde = {
      value: this.getValues( settings.cg_nav_donde, "value" ),
      show : this.getValues( settings.cg_nav_donde, "show" )
    };*/

    const nav_zoom = {
      value: this.getValues( settings.cg_nav_zoom, "value" ),
      show : this.getValues( settings.cg_nav_zoom, "show" )
    };

    // Fonts

    const font_size = {
      value: this.getValues( settings.cg_font_size, "value" ),
      show : this.getValues( settings.cg_font_size, "show" )
    };

    const font_legible = { show: this.getValues( settings.cg_font_legible, "show" ) };
    const font_dislexia = { show: this.getValues( settings.cg_font_dislexia, "show" ) };

    const font_dislexia_legible = {
      value: settings.cg_font_dislexia_legible || ""
    };

    const font_resaltar_links = {
      value: this.getValues( settings.cg_font_resaltar_links, "value" ),
      show : this.getValues( settings.cg_font_resaltar_links, "show" )
    };

    // Visual

    const visual_colors_bn = { show: this.getValues( settings.cg_visual_colors_bn, "show" ) };
    const visual_colors_invert = { show: this.getValues( settings.cg_visual_colors_invert, "show" ) };

    const visual_colors = {
      value: settings.cg_visual_colors || ""
    };

    const visual_resaltar_focus = {
      value: this.getValues( settings.cg_visual_resaltar_focus, "value" ),
      show : this.getValues( settings.cg_visual_resaltar_focus, "show" )
    };

    const visual_estilo_focus = {
      show: this.getValues( settings.visual_estilo_focus, "show" )
    }

    // Diseño

    const zoom_pau = {
      value: this.getValues( settings.cg_zoom_pau, "value" ),
      show : this.getValues( settings.cg_zoom_pau, "show" )
    };

    const diseno_pau_fondo = {
      value: settings.diseno_pau_fondo ? settings.diseno_pau_fondo.value : ''
    }

    const diseno_pau_primario = {
      value: settings.diseno_pau_primario ? settings.diseno_pau_primario.value : ''
    }

    const diseno_pau_secundario = {
      value: settings.diseno_pau_secundario ? settings.diseno_pau_secundario.value : ''
    }

    const diseno_custom_css = {
      value: settings.diseno_custom_css ? settings.diseno_custom_css.value : ''
    }

    return {
      perfil_defecto,
      font_dislexia_legible,
      visual_colors,
      geral_todo,
      geral_sonido,
      geral_video,
      geral_lectura,
      nav_bigcursor,
      nav_clic,
      //nav_donde,
      nav_zoom,
      font_size,
      font_legible,
      font_dislexia,
      font_resaltar_links,
      visual_colors_bn,
      visual_colors_invert,
      visual_resaltar_focus,
      visual_estilo_focus,
      diseno_pau_fondo,
      diseno_pau_primario,
      diseno_pau_secundario,
      diseno_custom_css,
      zoom_pau
    };

  }

  getValues( setting, key ) {

    if ( !setting ) return false;

    if ( setting.hasOwnProperty( key ) ) {

      let $key = setting[ key ];

      if ( typeof setting === "undefined" ) return false;

      if ( $key === "on" ) {
        return true;
      } else if ( isNumeric( $key ) ) {
        return parseInt( $key, 10 );
      }

    }

    return false;

  }

  apiPauCloud() {

    fetchX( this.fetchUrls.urlApi, ( data ) => {

        const { status } = data;
        global.licenseState = status;

        this.setState( update( this.state, {
          getLicense : { $set: true },
          licenseData: { $set: data }
        } ) );

    } );

  }
  execHotspots() {

    fetchX( this.fetchUrls.urlHotspots, ( data ) => {

      this.setState( update( this.state, {
        hotspots: { $set: data }
      } ) );

      let { menuEmptyPage, embeddedsEmptyPage } = data;

      this.managerHotspot.init( menuEmptyPage );
      this.managerHotspot.init( embeddedsEmptyPage );
      this.setState({ ...this.state, canPanelRendered: true } );

    } );

  }

  zoomMasMenos( type ) {

    const { cookies } = this.props;
    const settings = cookies.get( "settings" );
    const { zoom_pau } = settings;

    let value = zoom_pau.value;

    if ( type === "mas" ) {
      if ( zoom_pau.value !== 200 ) value += 10;
    } else if ( type === "menos" ) {
      if ( zoom_pau.value !== 70 ) value -= 10;
    }

    this.applyZoomPau( value, cookies, settings );

  }

  applyZoomPau( value, cookies, settings ) {
    global.zoom_pau = value;
    let zoomVal = {
      value: { $set: value }
    };

    let pauPanelZoomPerc = document.getElementById( "pauPanelZoomPerc" ),
        dzoom            = document.getElementById( "pauDisplayZoom" );

    pauPanelZoomPerc.childNodes[ 0 ].textContent = value;
    dzoom.childNodes[ 0 ].textContent = value;
    AccesibilityActions.zoomPanel( value );

    cookies.remove( "settings", { path: "/" } );
    cookies.set( "settings", update( settings, {
      zoom_pau: zoomVal
    } ), { path: "/" } );

    this.setState( update( this.state, {
      zoom_pau: { $set: value }
    } ) );

  }

  btnReset() {
    this.execSettings( true );
  }

  acceptAvisoPauPlay() {
    const { cookies } = this.props;
    cookies.set( "avisoPauPlay", false, { path: "/" } );
  }

  completedMount() {
    if ( this.hotspotsAfterPanel ) {
      let {hotspotsActualPage, actualPageContent, emergentEmptyPage } = this.state.hotspots;
      this.managerHotspot.init( emergentEmptyPage );
      this.managerHotspot.init( hotspotsActualPage );
      this.managerHotspot.init( actualPageContent );
      this.hotspotsAfterPanel = false;
    }

  }

  render() {

    const { getLicense, settings, licenseData, canPanelRendered} = this.state;
    const { cookies } = this.props;
    const avisoPauPlay = cookies.get( "avisoPauPlay" );

    return <>
      { canPanelRendered ?<Panel zoomMasMenos={this.zoomMasMenos.bind(this)}
                                 zoom_pau={this.state.zoom_pau}
                                 btnReset={this.btnReset.bind(this)}
                                 getSettings={this.getSettings.bind(this)}
                                 getValues={this.getValues.bind(this)}
                                 settings={settings}
                                 fetchUrls={this.fetchUrls}
                                 getLicense={getLicense}
                                 licenseData={licenseData}
                                 completedMount={this.completedMount.bind(this)}/>   : '' }
    </>

  }

}

export default withCookies( App );
