import React, {Component} from 'react';
import {pictosPanelHeader} from "../constants/pictosPanel";
import Main from "../includes/Main";
import FocusPanelButton from "./FocusPanelButton";

class Panel extends Component {

    constructor( props ) {
        super( props );
    }

    zoomMasMenos( type ) {
        return this.props.zoomMasMenos( type );
    }

    getValues( setting, key) {
        return this.props.getValues( setting, key );
    }

    getSettings( settings ) {
        return this.props.getSettings( settings );
    }

    completedMount(){
        return this.props.completedMount();
    }

    render() {
        return [
            <div
                key="pauContPanel"
                id="pauContPanel"
                data-value="1">
            </div>,
            <div key="pauContMain"
                 id="pauContMain"
                 data-value="1">
            </div>,
            <div data-key="iconPauAccess"
                 key="iconPauAccess"
                 className="iconPauAccess">
                <div className="pauSvgRender"
                     style={{display: "none"}}
                     pau-url-svg={`${pictosPanelHeader().icoPrin}`}></div>
            </div>,
            <div id="pauContenedor"
                 key="pauMain"
                 style={{backgroundColor: "#efefef"}}>
                {/*Cabecera del panel*/}
                <div id="pauHeaderAccess">
                    <div id="pauIconsHeader">
            <span className="pauActionHeader">
                <img onClick={this.zoomMasMenos.bind(this, "menos")}
                     src={`${pictosPanelHeader().menos}`}
                     alt="Botón para disminuir el tamaño del panel"/>
            </span>
                        <span id="pauDisplayZoom"
                              className="pauActionHeader">
                {this.props.zoom_pau}%
            </span>
                        <span className="pauActionHeader">
                <img onClick={this.zoomMasMenos.bind(this, "mas")}
                     src={`${pictosPanelHeader().mas}`}
                     alt="Botón para aumentar el tamaño del panel"/>
            </span>
                        <span className="pauActionHeader">
                <img onClick={this.props.btnReset}
                     src={`${pictosPanelHeader().reset}`}
                     alt="Botón para resetear las opciones"/>
            </span>
                    </div>
                </div>
                {this.props.getLicense && this.props.settings ?
                    <Main key="pauMain"
                          settings={this.props.settings}
                          getSettings={this.getSettings.bind(this)}
                          getValues={this.getValues.bind(this)}
                          zoomMasMenos={this.zoomMasMenos.bind(this)}
                          licenseData={this.props.licenseData}
                          fetchUrls={this.props.fetchUrls}
                          completedMount={this.completedMount.bind(this)}/> : ''}

            </div>,
            <FocusPanelButton key='focusPanel'/>,
        ];
    }

}

export default Panel;
